<!DOCTYPE html>
<html lang="en">
  <head>
    <title>
      Interest calculation
    </title>
  </head>

  <body>
    <h1>Warum kann uns nicht einfach jemand viel Geld schenken?</h1>
    <table>
      <tr>
        <td class="right">{{config.costs.id}}</td>
        <td><input [(ngModel)]="config.costs.val" name="{{config.costs.id}}" type="number"></td>
      </tr>
      <tr>
        <td class="right">{{config.equity.id}}</td>
        <td><input [(ngModel)]="config.equity.val" name="{{config.equity.id}}" type="number"></td>
      </tr>
      <tr>
        <td class="right">{{config.interest.id}}</td>
        <td><input [(ngModel)]="config.interest.val" name="{{config.interest.id}}" type="number"></td>
      </tr>
      <tr>
        <td class="right">{{config.reinDa.id}}</td>
        <td><input [(ngModel)]="config.reinDa.val" name="{{config.reinDa.id}}" type="number"></td>
      </tr>
    </table>

    <br>

    <input type="button" (click)="calc()" value="Komm zeigs mir">

    <br><br>

    <div *ngIf="errorMessage != ''">
      {{errorMessage}}
    </div>

    <div *ngIf="output.present">
      <p class="fat">Jahre/Monate in Ketten: {{output.monthYear}}</p>
      <p class="fat">Zinsen an die scheiß Bank: {{output.paidInterest.toFixed(2)}} €</p>

      <table style="border: 2px solid black; border-collapse: collapse;">
        <tr style="border-right: 1px solid black; border-collapse: collapse;">
          <th class="fullborder" style="border-bottom: 2px solid black;">Jahr / Monat</th>
          <th class="fullborder" style="border-bottom: 2px solid black;">Verbleibender Kredit</th>
          <th class="fullborder" style="border-bottom: 2px solid black;">Gezahlter Abtrag</th>
          <th class="fullborder" style="border-bottom: 2px solid black;">Gezahlte Zinsen (Monat)</th>
          <th class="fullborder" style="border-bottom: 2px solid black;">Gezahlte Zinsen (insgesamt)</th>
        </tr>
        <tr *ngFor="let item of output.outputItems">
          <td class="fullborder">{{item.monthYear}}</td>
          <td class="fullborder">{{item.leftQuantity}}</td>
          <td class="fullborder">{{item.paidDuty.toFixed(2)}}</td>
          <td class="fullborder">{{item.paidInterest.toFixed(2)}}</td>
          <td class="fullborder">{{item.paidInterestTotal.toFixed(2)}}</td>
        </tr>
      </table>
    </div>
  </body>

</html>
