import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'zins';

  config:Config = new Config()

  errorMessage = ""

  output: Output = new Output

  calc() {
    this.errorMessage = ""
    this.output = new Output
    let verifyMsg = this.verify()
    if (verifyMsg != "") {
      this.errorMessage = verifyMsg
      return
    }
    this.output.present = true
    let fullQuantity = this.config.costs.val - this.config.equity.val
    let reinDa = this.config.reinDa.val
    let currentQuantity = fullQuantity
    let fullPaidInterest = 0
    let monthNumber = 0
    let counter = 0
    while (currentQuantity > 0) {
      if (counter > 12*40) {
        this.errorMessage = "Lol das dauert ja länger als 40 Jahre."
        this.output = new Output
        return
      }
      let paidInterest = currentQuantity * this.config.interest.val / 100 / 12
      fullPaidInterest += paidInterest
      let paidCredit = reinDa - paidInterest
      currentQuantity -= paidCredit
      this.output.add(new OutputItem(currentQuantity, paidCredit, paidInterest, fullPaidInterest, ++monthNumber))
      counter++
    }
  }

  verify():string {
    if (this.config.costs.val <= 0) {
      return "Was für Kosten sollen das sein?";
    }
    if (this.config.equity.val < 0) {
      return "Schulden als Eigenkapital oder was lol";
    }
    if (this.config.interest.val < 0 || this.config.interest.val > 20) {
      return "Was für Zinsen amk"
    }
    if (this.config.reinDa.val <= 0) {
      return "Haha nix zurückzahlen oder was"
    }
    if ((this.config.costs.val - this.config.equity.val) * this.config.interest.val / 100 / 12 >= this.config.reinDa.val) {
      return "Lol damit deckst du nicht mal die Zinsen du Lurch"
    }

    return ""
  }
}

class Config {
  costs = new Config.Doub("Gesamtkosten Projekt", 400000)
  interest = new Config.Doub("Zinssatz in %", 4)
  equity = new Config.Doub("Kapital", 20000)
  reinDa = new Config.Doub("Monatliche Zahlung", 2500)

  static Doub = class {
    constructor(id: string, val: number) {
      this.id = id
      this.val = val
    }
    id: string
    val: number
  }
}


class Output {
  outputItems: OutputItem[] = []
  monthYear = ""
  paidInterest = 0
  present = false

  add(item: OutputItem) {
    this.outputItems.push(item)
    this.monthYear = item.monthYear
    this.paidInterest += item.paidInterest
    this.present = true
  }
}

class OutputItem {
  leftQuantity: string
  paidDuty: number
  paidInterest: number
  paidInterestTotal: number
  monthYear: string

  constructor(leftQuantity: number, paidDuty: number, paidInterest: number, paidInterestTotal: number, monthNumber: number) {
    this.leftQuantity = `${leftQuantity.toFixed(2)} €`
    this.paidDuty = paidDuty
    this.paidInterest = paidInterest
    this.paidInterestTotal = paidInterestTotal
    this.monthYear = `${Math.floor(monthNumber / 12)}y ${monthNumber % 12}m`
  }

}
